import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, subtitle, extrainfo, sectionid },
    } = this;

    const hidden = this.props.hidden && this.props.loadMore ? "hidden" : "";

    return (
      <>
        <div
          id={sectionid}
          className={
            isOpen
              ? `accordion-item open ${hidden}`
              : `accordion-item ${hidden}`
          }
        >
          <div
            className="accordion-header"
            onClick={onClick}
            role="presentation"
          >
            <div className="accordion-title">
              <h4>{label}</h4>
              {subtitle ? <p className="subtitle">{subtitle}</p> : null}
              {extrainfo ? <p className="extrainfo">{extrainfo}</p> : null}
            </div>

            <div className="accordion-arrow">
              <i className="icon-arrow-down" />
            </div>
          </div>
          <div className="accordion-body">{this.props.children}</div>
        </div>
      </>
    );
  }
}

export default AccordionSection;
